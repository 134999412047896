<template>
    <div class="container fluid">
        <div class="alert alert-success" role="alert">
            Nous avons bien reçu votre demande de virement. Votre compte sera crédité une fois votre virement reçu.<br />
            Vous pouvez retrouver les informations de paiement dans la rubrique
            <router-link to="/invoices">
                <b-icon icon="file-invoice" />
                {{ $t('factures.title') }}
            </router-link>
        </div>

        <div id="bankModalContainer" class="container">
            <p class="mx-6">{{ $t('achat-credits.payment-transferdescription') }} :</p>
            <div class="container d-flex">
                <div class="container rounded shadow-sm bg-white p-3 mx-3 my-2">
                    <!-- <p class="fw-bolder">{{ $t('user.payment.rib') }} :</p>
                    <p>
                        {{ $t('user.payment.bank') }} : 30003 <br />{{ $t('user.payment.bankdepot') }} : 01174 <br />{{
                            $t('user.payment.accountnumber')
                        }}
                        : 00027000607 <br />{{ $t('user.payment.key') }} : 59
                    </p>-->
                    <p class="font-weight-bold bg-success text-white p-3">Merci de préciser dans le libellé : NL-{{user.id}}</p>
                    <p><b>{{ $t('user.payment.bankaccountowner') }}</b> : {{ WIRE_OWNER }}</p>
                    <p><b>IBAN</b> : {{ WIRE_IBAN }}</p>
                    <p><b>BIC</b> : {{ WIRE_BIC }}</p>
                </div>
                <!--<div class="container rounded shadow-sm bg-white p-3 mx-3 my-2">
                    <p class="fw-bolder">{{ $t('user.payment.bankadress') }}:</p>
                    <p>SG ANDREZIEUX LOIRE ENT (01174)<br />1 RPT AUGUSTE COLONNA<br />42160 ANDREZIEUX-BOUTHEON</p>
                    <p>
                        {{ $t('user.payment.bankaccountowner') }} : <br />KORLEON'BIZ <br />428 Route du cimetière
                        <br />42110 Poncins
                    </p>
                </div>-->
            </div>
            <div class="container form-check m-3 d-flex flex-column align-items-center">
                <div v-if="amountHT >= 50" class="d-flex">
                    <input
                        id="flexCheckDefault"
                        v-model="bankButton"
                        class="form-check-input"
                        type="checkbox"
                        value=""
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                        {{ $t('achat-credits.validation') }}
                        <strong>{{ computeTTC }} TTC €.</strong>
                        {{ $t('achat-credits.validation_02') }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        data() {
            return {
                WIRE_IBAN: process.env.VUE_APP_WIRE_IBAN,
                WIRE_OWNER: process.env.VUE_APP_WIRE_OWNER,
                WIRE_BIC: process.env.VUE_APP_WIRE_BIC,
            }
        },
        computed: {
            ...mapState('auth', {
                user: state => state.user,
            }),
        }
    }
</script>

<style></style>
